// Layout.js
import React, { useState } from 'react';
import { NavBar } from './NavBar';

const Layout = ({ children, userProfile, setUserProfile, updateUserProfile }) => {
  const [showSignInPopup, setShowSignInPopup] = useState(false);

  const handleSignInClick = () => {
    setShowSignInPopup(true);
  };

  // Clone children to pass handleSignInClick prop
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        onSignInClick: handleSignInClick,
        showSignInPopup,
        setShowSignInPopup,
      });
    }
    return child;
  });

  return (
    <>
      <NavBar
        userProfile={userProfile}
        setUserProfile={setUserProfile}
        updateUserProfile={updateUserProfile}
        showSignInPopup={showSignInPopup}
        setShowSignInPopup={setShowSignInPopup}
      />
      {childrenWithProps}
    </>
  );
};

export default Layout;