import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Button, Spinner, Modal } from 'react-bootstrap';
import { FaBed, FaBath, FaRulerCombined, FaEnvelope, FaExternalLinkAlt, FaUser, FaQrcode, FaDownload, FaBuilding, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
import { MdVerified } from 'react-icons/md';
import styled from 'styled-components';
import { defaultInstance } from '../utils/auth';

const StyledContainer = styled(Container)`
  padding-top: 10rem; 
  padding-bottom: 2rem;
  max-width: 1200px; 
  margin: 0 auto;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
`;

const ErrorContainer = styled.div`
  text-align: center;
  padding: 2rem;
  color: #dc3545;
`;

const PropertyImage = styled.img`
  display: block; 
  margin: 0 auto; 
  max-width: 80%; 
  max-height: 400px;
  object-fit: contain; 
  border-radius: 12px;
  margin-bottom: 1.5rem; 
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); 
`;



const PropertyTitle = styled.h1`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  
  .verified-icon {
    color: green;
    font-size: 1.2rem;
  }
`;

const Location = styled.div`
  font-size: 1.2rem;
  color: #6c757d;
  margin-bottom: 1rem;
  font-weight: 500;
`;

const PriceSection = styled.div`
  display: flex;
  justify-content: space-between; 
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e9ecef;

  .verified-icon {
    color: green; 
    font-size: 1.5rem; 
  }
`;



const Compensation = styled.div`
  font-size: 1.4rem;
  color: #2a4b8d;
`;

const Details = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 1.5rem 0;

  .detail-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    
    svg {
      color: #2a4b8d;
      font-size: 1.2rem;
    }
  }
`;

const Description = styled.div`
  margin-bottom: 2rem;
  line-height: 1.6;
`;

const ActionButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

const ActionButton = styled(Button)`
  background-color: #2a4b8d;
  border-color: #2a4b8d;
  color: white;
  text-decoration: none;
  padding: 0.5rem 1.5rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  min-width: 160px;
  justify-content: center;
  
  &:hover, &:focus, &:active {
    background-color: #1a3b7d;
    border-color: #1a3b7d;
    color: white;
    text-decoration: none;
  }
`;

const AgentInfo = styled.div`
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 12px;
  margin-bottom: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  border: 1px solid #e9ecef;

  .agent-header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
    color: #2a4b8d;
    font-size: 1.2rem;

    h4 {
      margin: 0;
      font-weight: 600;
    }
  }

  .agent-detail {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 0;
    color: #495057;

    svg {
      color: #2a4b8d;
    }
  }
`;
const Price = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: bold;
  color: #2a4b8d;
  gap: 0.5rem; 
`;


const QRCodeModal = styled(Modal)`
  .modal-content {
    border-radius: 12px;
    border: none;
  }
  
  .modal-header {
    background-color: #2a4b8d;
    color: white;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom: none;
  }
  
  .modal-body {
    padding: 2rem;
    text-align: center;
  }
  
  .qr-image {
    width: 300px;
    height: 300px;
    margin: 0 auto 1rem;
  }
  
  .property-address {
    color: #495057;
    font-size: 1.1rem;
  }
`;

const PropertyDetails = () => {
  const { id } = useParams();
  const [property, setProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showQRModal, setShowQRModal] = useState(false);

  useEffect(() => {
    const fetchProperty = async () => {
      try {
        const response = await defaultInstance.get(`api/properties/${id}/`);
        setProperty(response.data);
      } catch (err) {
        console.error('Error fetching property:', err);
        setError('Failed to load property details');
      } finally {
        setLoading(false);
      }
    };

    fetchProperty();
  }, [id]);

  if (loading) {
    return (
      <LoadingContainer>
        <Spinner animation="border" variant="primary" />
        <div>Loading property {id}...</div>
      </LoadingContainer>
    );
  }

  if (error || !property) {
    return (
      <ErrorContainer>
        <h3>{error || 'Property not found'}</h3>
        <div>Property ID: {id}</div>
      </ErrorContainer>
    );
  }

  const isVerified = Boolean(
    property?.street_address &&
    property?.city &&
    property?.zip_code &&
    property?.price &&
    property?.bedrooms &&
    property?.bathrooms &&
    property?.sq_ft &&
    property?.description &&
    property?.pdf_file &&
    property?.image &&
    property?.concession
  );
  


  const formatNumber = (num) => {
    if (!num) return '0';
    return Math.floor(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleEmailClick = () => {
    if (!property.email) return;
    const subject = `Inquiry about property: ${property.street_address}`;
    const body = `I'm interested in the property at ${property.street_address}`;
    window.location.href = `mailto:${property.email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };

  const handleQRCodeClick = () => {
    setShowQRModal(true);
  };

  const handleDocumentClick = () => {
    if (property.pdf_file) {
      window.open(property.pdf_file, '_blank');
    }
  };

  const formatCompensation = () => {
    if (!property.concession) return null;
    const metadata = localStorage.getItem(`property_${property.id}_metadata`);
    const storedData = metadata ? JSON.parse(metadata) : null;
    
    const concessionType = storedData?.concessionType || property.concessionType;
    return concessionType === 'percent' ? 
      `${property.concession}%` : 
      `$${formatNumber(property.concession)}`;
  };

  const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=${encodeURIComponent(property.next_property_url || window.location.href)}`;

  return (
    <StyledContainer>
      <PropertyImage
        src={property.image || '/placeholder.svg'}
        alt={property.street_address}
      />
      
      <Row>
        <Col lg={8}>
        <PriceSection>
  <Price>
    ${formatNumber(property.price)}
    {isVerified && <MdVerified className="verified-icon" />}
  </Price>
  {formatCompensation() && (
    <Compensation>
      Compensation: {formatCompensation()}
    </Compensation>
  )}
</PriceSection>


          <PropertyTitle>
            {property.street_address}
          </PropertyTitle>
          
          <Location>
            {property.city}, {property.state} {property.zip_code}
          </Location>

          
          
          <Details>
            {property.bedrooms && (
              <div className="detail-item">
                <FaBed />
                <span>{property.bedrooms} beds</span>
              </div>
            )}
            {property.bathrooms && (
              <div className="detail-item">
                <FaBath />
                <span>{Math.floor(property.bathrooms)} baths</span>
              </div>
            )}
            {property.sq_ft && (
              <div className="detail-item">
                <FaRulerCombined />
                <span>{formatNumber(property.sq_ft)} sqft</span>
              </div>
            )}
          </Details>

          <Description>
            {property.description}
          </Description>

          <ActionButtons>
            <ActionButton onClick={handleEmailClick}>
              <FaEnvelope />
              Contact Agent
            </ActionButton>
            {property.next_property_url && (
              <ActionButton 
                as="a" 
                href={property.next_property_url} 
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaExternalLinkAlt />
                Property URL
              </ActionButton>
            )}
            <ActionButton onClick={handleQRCodeClick}>
              <FaQrcode />
              QR Code
            </ActionButton>
            {property.pdf_file && (
              <ActionButton onClick={handleDocumentClick}>
                <FaDownload />
                Documents
              </ActionButton>
            )}
          </ActionButtons>
        </Col>

        <Col lg={4}>
          <AgentInfo>
            <div className="agent-header">
              <FaUser />
              <h4>Listed By</h4>
            </div>
            {property.owner_details && (
              <>
                <div className="agent-detail">
                  <FaUser />
                  <strong>{property.owner_details.name || property.owner_details.username}</strong>
                </div>
                {property.owner_details.profile?.company && (
                  <div className="agent-detail">
                    <FaBuilding />
                    <span>{property.owner_details.profile.company}</span>
                  </div>
                )}
                {property.owner_details.profile?.phone && (
                  <div className="agent-detail">
                    <FaPhone />
                    <span>{property.owner_details.profile.phone}</span>
                  </div>
                )}
                {property.owner_details.profile?.state && (
                  <div className="agent-detail">
                    <FaMapMarkerAlt />
                    <span>{property.owner_details.profile.state}</span>
                  </div>
                )}
              </>
            )}
          </AgentInfo>
        </Col>
      </Row>

      <QRCodeModal
        show={showQRModal}
        onHide={() => setShowQRModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Property QR Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={qrCodeUrl}
            alt="Property QR Code"
            className="qr-image"
          />
          <div className="property-address">
            {property.street_address}, {property.city}, {property.zip_code}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ActionButton onClick={() => setShowQRModal(false)}>
            Close
          </ActionButton>
        </Modal.Footer>
      </QRCodeModal>
    </StyledContainer>
  );
};

export default PropertyDetails;