import axios from 'axios';

const createAdminAPIInstance = () => {
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Authorization': `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
    }
  });
  return api;
};

const createAPIInstance = (mockLocation, userProfile, skipLocationCheck = false) => {
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  api.interceptors.request.use(async (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Token ${token}`;
    }

    if (skipLocationCheck || 
        config.url.includes('/login/') || 
        config.url.includes('/register/')) {
      return config;
    }

    if (!userProfile?.profile?.state) {
      return config;
    }

    try {
      if (mockLocation?.enabled) {
        config.headers['X-User-Latitude'] = '36.7783';
        config.headers['X-User-Longitude'] = '-119.4179';
        config.headers['X-User-State'] = userProfile.profile.state;
      } else {
        const position = await getCurrentPosition();
        const coords = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        };

        if (config.url.includes('/properties/')) {
          const stateMatch = await verifyStateMatch(coords, userProfile.profile.state);
          if (!stateMatch) {
            throw new Error('state_mismatch');
          }
        }

        config.headers['X-User-Latitude'] = coords.latitude.toString();
        config.headers['X-User-Longitude'] = coords.longitude.toString();
        config.headers['X-User-State'] = userProfile.profile.state;
      }
    } catch (error) {
      if (error.message === 'state_mismatch') {
        throw new Error(`location_restricted:${userProfile.profile.state}`);
      }
      throw error;
    }

    return config;
  });

  return api;
};

const verifyStateMatch = async (coords, registeredState) => {
  if (coords.latitude === 36.7783 && coords.longitude === -119.4179) {
    return true;
  }

  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords.latitude},${coords.longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
    );

    if (!response.data.results?.[0]?.address_components) {
      return false;
    }

    const stateComponent = response.data.results[0].address_components.find(
      comp => comp.types.includes('administrative_area_level_1')
    );

    if (!stateComponent) {
      return false;
    }

    const browserState = stateComponent.short_name;
    return browserState === registeredState;
  } catch (error) {
    return false;
  }
};

const getCurrentPosition = () => {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      reject(new Error('Geolocation is not supported'));
      return;
    }

    navigator.geolocation.getCurrentPosition(resolve, reject, {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    });
  });
};

const verifyLocationAccess = async (api, userProfile) => {
  try {
    const response = await api.get('api/user/profile/');
    const userState = response.data.profile?.state;
    
    if (!userState) {
      throw new Error('User state not found in profile');
    }
    return true;
  } catch (error) {
    throw error;
  }
};

const defaultInstance = createAPIInstance({
  enabled: false,
  coordinates: { lat: 0, lng: 0 },
  state: ''
});

const getUserLocation = () => {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      reject(new Error("Geolocation is not supported by your browser"));
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        });
      },
      (error) => {
        switch (error.code) {
          case error.PERMISSION_DENIED:
            reject(new Error("Location access denied. Please enable location services to view properties."));
            break;
          case error.POSITION_UNAVAILABLE:
            reject(new Error("Location information is unavailable. Please try again."));
            break;
          case error.TIMEOUT:
            reject(new Error("Location request timed out. Please try again."));
            break;
          default:
            reject(new Error("An error occurred while getting your location."));
        }
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      }
    );
  });
};

export const register = async (username, email, password, stateId) => {
  try {
    const response = await defaultInstance.post('/api/register/', {
      username,
      email,
      password,
      profile: { state: stateId }  
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else if (error.request) {
      throw new Error('No response received from the server');
    } else {
      throw new Error('Error setting up the request');
    }
  }
};

export const verifyEmail = async (uidb64, token) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/verify-email/${uidb64}/${token}/`;
    const response = await axios.get(url);
    return { 
      success: true, 
      message: response.data.message || 'Email verified successfully. You can now log in.' 
    };
  } catch (error) {
    return { 
      success: false, 
      message: error.response?.data?.error || 'Registration link expired or invalid. Please try again.'
    };
  }
};

export const login = async (username, password) => {
  try {
    const api = createAPIInstance(null, null, true);
    const response = await api.post('api/login/', { username, password });
    
    if (response.data?.token) {
      localStorage.setItem('token', response.data.token);
      return response.data;
    }
    throw new Error('Invalid response from server');
  } catch (error) {
    throw error;
  }
};

export const requestPasswordReset = async (email) => {
  try {
    const response = await defaultInstance.post('api/password-reset/', { email });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (uidb64, token, newPassword) => {
  try {
    const response = await defaultInstance.post(`api/password-reset-confirm/${uidb64}/${token}/`, {
      new_password: newPassword
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      throw new Error(error.response.data.error || 'An error occurred while resetting the password.');
    } else {
      throw new Error('An unexpected error occurred.');
    }
  }
};

export const fetchUserProfile = async (userId = null) => {
  try {
    const endpoint = userId ? `api/user/profile/${userId}/` : 'api/user/profile/';
    const profileResponse = await defaultInstance.get(endpoint);
    const subscriptionResponse = await defaultInstance.get('api/user/subscription/');
    
    const userProfile = {
      ...profileResponse.data,
      name: profileResponse.data.name || `${profileResponse.data.first_name || ''} ${profileResponse.data.last_name || ''}`.trim(),
      subscription: subscriptionResponse.data
    };
    return userProfile;
  } catch (error) {
    throw error;
  }
};

export const fetchAllProperties = async (mockLocation, userProfile) => {
  const api = userProfile ? createAPIInstance(mockLocation, userProfile) : defaultInstance;
  
  try {
    const initialResponse = await api.get('api/properties/', {
      params: {
        page: 1,
        limit: 100,
        state: userProfile?.profile?.state
      },
      headers: {
        'X-User-State': userProfile?.profile?.state,
        'State': userProfile?.profile?.state
      }
    });

    let allProperties = Array.isArray(initialResponse.data) ? 
      initialResponse.data : 
      initialResponse.data.properties || [];

    allProperties = allProperties.map(property => ({
      ...property,
      state: property.state || userProfile?.profile?.state
    }));

    if (initialResponse.data.total_pages) {
      const totalPages = initialResponse.data.total_pages;
      const remainingRequests = [];
      
      for (let page = 2; page <= totalPages; page++) {
        remainingRequests.push(
          api.get('api/properties/', {
            params: {
              page: page,
              limit: 100,
              state: userProfile?.profile?.state
            },
            headers: {
              'X-User-State': userProfile?.profile?.state,
              'State': userProfile?.profile?.state
            }
          })
        );
      }

      if (remainingRequests.length > 0) {
        const remainingResponses = await Promise.all(remainingRequests);
        remainingResponses.forEach(response => {
          const pageProperties = Array.isArray(response.data) ? 
            response.data : 
            response.data.properties || [];
            
          const propertiesWithState = pageProperties.map(property => ({
            ...property,
            state: property.state || userProfile?.profile?.state
          }));
          
          allProperties = [...allProperties, ...propertiesWithState];
        });
      }
    }

    return {
      properties: allProperties
    };

  } catch (error) {
    if (userProfile) {
      handleLocationError(error, userProfile);
    }
    throw error;
  }
};

const handleLocationError = (error, userProfile) => {
  if (error.message.startsWith('location_restricted')) {
    throw new Error(`Access restricted. Properties can only be accessed from ${userProfile.profile.state}`);
  } else if (error.code === 1) {
    throw new Error('Please enable location services to view properties');
  }
  throw error;
};

export const uploadProperty = async (formData, mockLocation, userProfile, onProgress) => {
  const api = createAPIInstance(mockLocation, userProfile);
  
  if (userProfile?.profile?.state) {
    formData.delete('state');
    formData.set('state', userProfile.profile.state);
  }

  try {
    const response = await api.post('api/properties/', formData, {
      headers: { 
        'Content-Type': 'multipart/form-data',
        'X-User-State': userProfile?.profile?.state,
        'State': userProfile?.profile?.state
      },
      onUploadProgress: (e) => {
        if (onProgress && typeof onProgress === 'function') {
          onProgress(Math.round((e.loaded * 100) / e.total));
        }
      }
    });

    if (!response.data.state && userProfile?.profile?.state) {
      response.data.state = userProfile.profile.state;
    }

    return response.data;
  } catch (error) {
    if (error.response?.status === 403) {
      throw new Error(`Location access restricted. Properties can only be uploaded from ${userProfile?.profile?.state}`);
    }
    throw error;
  }
};

export const updateUserProfile = async (updatedFields) => {
  try {
    const dataToUpdate = {
      name: updatedFields.name,
      profile: {}
    };

    if (updatedFields.phone) dataToUpdate.profile.phone = updatedFields.phone;
    if (updatedFields.company) dataToUpdate.profile.company = updatedFields.company;
    if (updatedFields.state) dataToUpdate.profile.state = updatedFields.state;
    if (updatedFields.state_id) dataToUpdate.profile.state_id = updatedFields.state_id;
    if (updatedFields.business_address) dataToUpdate.profile.business_address = updatedFields.business_address;

    const response = await defaultInstance.put('api/user/profile/update/', dataToUpdate);

    const updatedProfile = {
      ...response.data.user,
      name: response.data.user.name || updatedFields.name,
      profile: {
        ...response.data.user.profile
      }
    };

    return { user: updatedProfile };
  } catch (error) {
    throw error;
  }
};

export const fetchUploads = async () => {
  try {
    const response = await defaultInstance.get('api/user/uploads/');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const activateSubscription = async (paymentMethodId, profileData) => {
  try {
    const requestData = {
      paymentMethodId,
      name: profileData.name,
      email: profileData.email,
      phone: profileData.phone,
      company: profileData.company,
      state: profileData.state,
      state_id: profileData.state_id,
      business_address: profileData.business_address
    };

    const response = await defaultInstance.post('api/subscribe/', requestData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchActiveUsers = async () => {
  try {
    const api = createAdminAPIInstance();
    const response = await api.get('/api/users/active/');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchUserDetails = async (userId) => {
  try {
    const adminApi = createAdminAPIInstance();
    const activeUsersResponse = await adminApi.get('/api/users/active/');
    const activeUsers = activeUsersResponse.data.users || [];
    
    const user = activeUsers.find(u => u.id === parseInt(userId));
    if (!user) {
      throw new Error('User not found');
    }

    const profileResponse = await defaultInstance.get(`api/user/profile/${userId}/`);
    
    const userData = {
      ...user,
      ...profileResponse.data,
      name: user.name || profileResponse.data.name || `${user.first_name || ''} ${user.last_name || ''}`.trim()
    };

    try {
      const subscriptionResponse = await defaultInstance.get('api/user/subscription/');
      userData.subscription = subscriptionResponse.data;
    } catch (err) {
      userData.subscription = null;
    }

    return userData;

  } catch (error) {
    if (error.message === 'User not found' || error.response?.status === 404) {
      throw new Error('User not found');
    }
    throw error;
  }
};

export const fetchBillingInfo = async () => {
  try {
    const response = await defaultInstance.get('api/user/billing/');
    return response.data;
  } catch (error) {
    console.error('Error fetching billing info:', error);
    throw error;
  }
};

export const updateBillingInfo = async (data) => {
  try {
    const response = await defaultInstance.put('api/user/billing/', data);
    return response.data;
  } catch (error) {
    console.error('Error updating billing info:', error);
    throw error;
  }
};
export const deleteProperty = async (id, mockLocation, userProfile) => {
  const token = localStorage.getItem('token');
  
  console.log('[API Delete] Starting request:', {
    id,
    mockEnabled: mockLocation?.enabled,
    userState: userProfile?.profile?.state,
    hasToken: !!token,
    tokenPreview: token,
    baseURL: process.env.REACT_APP_API_URL
  });

  if (!id) {
    console.error('[API Delete] Missing property ID');
    throw new Error('Property ID is required');
  }

  const api = createAPIInstance(mockLocation, userProfile);

  try {
    // Log request configuration
    console.log('[API Delete] Request configuration:', {
      url: `/api/properties/${id}/`,
      headers: {
        'Authorization': token ? `Token ${token.substring(0, 10)}...` : 'missing',
        'X-User-State': userProfile?.profile?.state,
        'X-User-Latitude': mockLocation?.enabled ? '36.7783' : 'browser location',
        'X-User-Longitude': mockLocation?.enabled ? '-119.4179' : 'browser location'
      }
    });

    const response = await api.delete(`/api/properties/${id}/`);
    
    console.log('[API Delete] Success response:', {
      status: response.status,
      statusText: response.statusText,
      data: response.data,
      headers: response.headers
    });

    return response.data;

  } catch (error) {
    console.error('[API Delete] Error details:', {
      message: error.message,
      code: error.code,
      status: error.response?.status,
      statusText: error.response?.statusText,
      errorData: error.response?.data,
      errorHeaders: error.response?.headers,
      errorConfig: error.config
    });

    if (error.response) {
      console.error('[API Delete] Server response:', {
        status: error.response.status,
        data: error.response.data,
        headers: error.response.headers
      });
    }

    throw error;
  }
};

export const updateProperty = async (id, formData, mockLocation, userProfile) => {
  const token = localStorage.getItem('token');
  
  console.log('[API Update] Starting request:', {
    id,
    mockEnabled: mockLocation?.enabled,
    userState: userProfile?.profile?.state,
    hasToken: !!token,
    tokenPreview: token ? `${token.substring(0, 10)}...` : 'missing',
    formDataEntries: Array.from(formData.entries()).map(([key, value]) => {
      // Don't log file contents
      if (value instanceof File) {
        return [key, `File: ${value.name} (${value.type})`];
      }
      return [key, value];
    }),
    baseURL: process.env.REACT_APP_API_URL
  });

  const api = createAPIInstance(mockLocation, userProfile);

  try {
    // Log request configuration
    console.log('[API Update] Request configuration:', {
      url: `/api/properties/${id}/`,
      method: 'PUT',
      headers: {
        'Authorization': token ? `Token ${token.substring(0, 10)}...` : 'missing',
        'Content-Type': 'multipart/form-data',
        'X-User-State': userProfile?.profile?.state,
        'X-User-Latitude': mockLocation?.enabled ? '36.7783' : 'browser location',
        'X-User-Longitude': mockLocation?.enabled ? '-119.4179' : 'browser location'
      }
    });

    const response = await api.put(`/api/properties/${id}/`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    
    console.log('[API Update] Success response:', {
      status: response.status,
      statusText: response.statusText,
      data: response.data,
      headers: response.headers
    });

    return response.data;

  } catch (error) {
    console.error('[API Update] Error details:', {
      message: error.message,
      code: error.code,
      status: error.response?.status,
      statusText: error.response?.statusText,
      errorData: error.response?.data,
      errorHeaders: error.response?.headers,
      errorConfig: error.config
    });

    if (error.response) {
      console.error('[API Update] Server response:', {
        status: error.response.status,
        data: error.response.data,
        headers: error.response.headers
      });
    }

    throw error;
  }
};

export const property_search = async (query, mockLocation, userProfile) => {
  console.log('[Search API] Starting search with:', { query, mockLocation, userProfile });
  const api = createAPIInstance(mockLocation, userProfile);
  try {
    // Log the full URL before making the request
    const searchUrl = `api/property-search/?q=${encodeURIComponent(query)}`;
    console.log('[Search API] Making request to:', searchUrl);

    const response = await api.get(searchUrl);
    console.log('[Search API] Raw response:', response);
    
    // Ensure we handle the response correctly
    let properties = [];
    if (response.data) {
      if (Array.isArray(response.data)) {
        properties = response.data;
      } else if (response.data.properties) {
        properties = response.data.properties;
      }
    }
      
    console.log('[Search API] Processed properties:', properties);
    
    // Return an empty array if no properties found
    return properties || [];
  } catch (error) {
    console.error('[Search API] Error searching properties:', {
      error: error.message,
      response: error.response?.data,
      url: `api/property-search/?q=${encodeURIComponent(query)}`,
      status: error.response?.status
    });
    return [];  // Return empty array instead of throwing
  }
};

export const changePassword = async (oldPassword, newPassword, confirmPassword) => {
  try {
    const response = await defaultInstance.post('api/user/update-password/', {
      old_password: oldPassword,
      new_password: newPassword,
      confirm_password: confirmPassword
    });
    return response.data;
  } catch (error) {
    console.error('Error changing password:', error);
    if (error.response && error.response.data) {
      throw new Error(error.response.data.error || 'An error occurred while changing the password.');
    } else {
      throw new Error('An unexpected error occurred.');
    }
  }
};

const objectToFormData = (obj) => {
  const formData = new FormData();
  Object.entries(obj).forEach(([key, value]) => {
    if (value !== null && value !== undefined) {
      formData.append(key, value);
    }
  });
  return formData;
};

// Create API instance for folder operations
const folderAPI = createAPIInstance();

// Folder operations
export const getFolders = async () => {
  try {
    const response = await defaultInstance.get('/api/folders/');
    return response.data;
  } catch (error) {
    console.error('Error fetching folders:', error);
    throw error?.response?.data?.error || error.message;
  }
};

export const createFolder = async (name, description = '') => {
  try {
    const response = await defaultInstance.post('/api/folders/', {
      name: name.trim(),
      description: description?.trim() || ''
    });
    return response.data;
  } catch (error) {
    console.error('Error creating folder:', error);
    throw error?.response?.data?.error || error.message;
  }
};

export const getFolderDetails = async (folderId) => {
  try {
    console.log('[Folders] Getting folder details:', folderId);
    const response = await folderAPI.get(`/api/folders/${folderId}/`);
    console.log('[Folders] Folder details response:', response.data);
    return response.data;
  } catch (error) {
    console.error('[Folders] Error fetching folder details:', error);
    throw error?.response?.data?.error || error.message;
  }
};


export const deleteFolder = async (folderId) => {
  try {
    console.log('[Folders] Deleting folder:', folderId);
    await folderAPI.delete(`/api/folders/${folderId}/`);
    console.log('[Folders] Delete folder successful');
    return true;
  } catch (error) {
    console.error('[Folders] Error deleting folder:', error);
    throw error?.response?.data?.error || error.message;
  }
};

export const getFolderProperties = async (folderId) => {
  try {
    const response = await defaultInstance.get(`/api/folders/${folderId}/properties/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching folder properties:', error);
    throw error?.response?.data?.error || error.message;
  }
};

export const movePropertyBetweenFolders = async (propertyId, sourceFolderId, targetFolderId) => {
  try {
    const response = await defaultInstance.post(`/api/properties/${propertyId}/move/`, {
      source_folder_id: sourceFolderId,
      target_folder_id: targetFolderId
    });
    return response.data;
  } catch (error) {
    console.error('Error moving property between folders:', error);
    throw error?.response?.data?.error || error.message;
  }
};

export const savePropertyToFolder = async (propertyId, { folder_id, notes = '' }) => {
  try {
    const data = {
      folder_id: Number(folder_id),
      notes: notes?.trim() || ''
    };

    const response = await defaultInstance.post(
      `/api/properties/${propertyId}/save-to-folder/`,
      data
    );
    return response.data;
  } catch (error) {
    console.error('Error saving property to folder:', error);
    if (error.response?.data?.message) {
      throw new Error(error.response.data.message);
    }
    throw error;
  }
};

export const removePropertyFromFolder = async (folderId, propertyId) => {
  try {
    await defaultInstance.delete(`/api/folders/${folderId}/properties/${propertyId}/`);
    return true;
  } catch (error) {
    console.error('Error removing property from folder:', error);
    throw error?.response?.data?.error || error.message;
  }
};

// Helper function for error handling
const handleApiError = (error, customMessage) => {
  console.error(customMessage, error);
  if (error.response?.data?.error) {
    throw new Error(error.response.data.error);
  }
  throw error;
};

// Add these functions to your auth.js file

// Add these new folder-related API endpoints in auth.js

export const getFoldersContainingProperty = async (propertyId) => {
  try {
    const response = await folderAPI.get(`api/properties/${propertyId}/saved-folders/`);
    return response.data;
  } catch (error) {
    throw handleApiError(error, 'Error fetching property folders:');
  }
};

export const updateFolder = async (folderId, { name, description }) => {
  try {
    const response = await defaultInstance.put(`/api/folders/${folderId}/`, {
      name: name.trim(),
      description: description?.trim() || ''
    });
    return response.data;
  } catch (error) {
    console.error('Error updating folder:', error);
    throw error?.response?.data?.error || error.message;
  }
};

export const updatePropertyNotesInFolder = async (folderId, propertyId, notes) => {
  try {
    const response = await defaultInstance.put(
      `/api/folders/${folderId}/properties/${propertyId}/notes/`,
      { notes: notes?.trim() || '' }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating property notes:', error);
    throw error?.response?.data?.error || error.message;
  }
};
export const submitContactForm = async (name, email, message) => {
  try {
    const response = await defaultInstance.post('/api/contact/', {
      name,
      email,
      message,
    });
    console.log('Contact form submission response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error submitting contact form:', error);
    if (error.response && error.response.data) {
      throw new Error(error.response.data.error || 'An error occurred while submitting the contact form.');
    } else {
      throw new Error('An unexpected error occurred.');
    }
  }
};

export {
  defaultInstance,
  createAPIInstance,
};


export const logout = () => {
  localStorage.removeItem('token');
};