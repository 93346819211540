import React, { useState } from 'react';
import { FaTimes, FaEye, FaEyeSlash, FaCheck, FaTimes as FaCross, FaChevronDown } from 'react-icons/fa';
import { register } from '../utils/auth';
import usStates from '../utils/usStates';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SignUpPopup = ({ onClose, onSignInClick, onSignUpSuccess }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [stateId, setStateId] = useState('');
    const [username, setUsername] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [passwordFocused, setPasswordFocused] = useState(false);
    const [isVerificationSent, setIsVerificationSent] = useState(false);
    const [stateInput, setStateInput] = useState('');
    const [showStateDropdown, setShowStateDropdown] = useState(false);
    const [passwordRequirements, setPasswordRequirements] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
        special: false
    });
    const [filteredStates, setFilteredStates] = useState(usStates);

    const validatePassword = (password) => {
        const requirements = {
            length: password.length >= 8,
            uppercase: /[A-Z]/.test(password),
            lowercase: /[a-z]/.test(password),
            number: /[0-9]/.test(password),
            special: /[@$!%*?&]/.test(password)
        };
        setPasswordRequirements(requirements);
        return Object.values(requirements).every(Boolean);
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        validatePassword(newPassword);
    };

    const handleStateChange = (e) => {
        const value = e.target.value;
        setStateInput(value);
        setStateId('');
        filterStates(value);
        setShowStateDropdown(true);
    };

    const filterStates = (value) => {
        const filtered = usStates.filter(state => 
            state.name.toLowerCase().includes(value.toLowerCase()) ||
            state.abbreviation.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredStates(filtered);
    };

    const selectState = (state) => {
        setStateId(state.name);
        setStateInput(state.name);
        setShowStateDropdown(false);
    };

    const resetState = () => {
        setStateId('');
        setStateInput('');
        setFilteredStates(usStates);
    };

    const handleSignUp = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');
      
        if (!validatePassword(password)) {
          toast.error('Please ensure all password requirements are met.');
          setIsLoading(false);
          return;
        }
      
        if (!stateId) {
          toast.error('Please select a state from the dropdown.');
          setIsLoading(false);
          return;
        }
      
        try {
          const data = await register(username, email, password, stateId);
          //console.log('Registration successful:', data);
          setIsVerificationSent(true);
          toast.success('Registration successful. Please check your email to verify your account.');
        } catch (error) {
          console.error('Registration error:', error);
          
          if (error.response && error.response.data) {
            const errorData = error.response.data;
            if (typeof errorData === 'object') {
              const errorMessage = Object.values(errorData)[0];
              if (Array.isArray(errorMessage)) {
                toast.error(errorMessage[0]);
              } else {
                toast.error(errorMessage);
              }
            } else {
              toast.error(errorData);
            }
          } else if (error.message) {
            toast.error(error.message);
          } else {
            toast.error('An unexpected error occurred. Please try again.');
          }
        } finally {
          setIsLoading(false);
        }
      };

    const handleSignInClick = () => {
        onClose();
        onSignInClick();
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    

    return (
        <div className="popup-overlay">
            <ToastContainer />
            <div className="popup-container">
                <div className="popup-content">
                    {isVerificationSent ? (
                        <div>
                            <h2>Verify Your Email</h2>
                            <p>A verification email has been sent to your account. Please check your email and click on the verification link to complete your registration.</p>
                        </div>
                    ) : (
                        <div>
                            <h2>Join Us.</h2>
                            <p className='heading-popup'>Create Account</p>
                            {error && <p className="error-message">{error}</p>}
                            <div className="signup-options">
                                <form onSubmit={handleSignUp}>
                                    <input
                                        type="text"
                                        placeholder="Username"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        required
                                    />
                                    <input
                                        type="email"
                                        placeholder="Email Address"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                    <div className="password-input-group">
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            placeholder="Password"
                                            value={password}
                                            onChange={handlePasswordChange}
                                            onFocus={() => setPasswordFocused(true)}
                                            onBlur={() => setPasswordFocused(false)}
                                            required
                                            className={password && !Object.values(passwordRequirements).every(Boolean) ? 'invalid-password' : ''}
                                        />
                                        <button
                                            type="button"
                                            onClick={togglePasswordVisibility}
                                            className="password-toggle-btn"
                                        >
                                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                                        </button>
                                    </div>
                                    {passwordFocused && password && (
                                        <div className="password-requirements">
                                            <p>Password must:</p>
                                            <ul>
                                                <li className={passwordRequirements.length ? 'met' : 'unmet'}>
                                                    {passwordRequirements.length ? <FaCheck /> : <FaCross />} Be at least 8 characters long
                                                </li>
                                                <li className={passwordRequirements.uppercase ? 'met' : 'unmet'}>
                                                    {passwordRequirements.uppercase ? <FaCheck /> : <FaCross />} Contain at least one uppercase letter
                                                </li>
                                                <li className={passwordRequirements.lowercase ? 'met' : 'unmet'}>
                                                    {passwordRequirements.lowercase ? <FaCheck /> : <FaCross />} Contain at least one lowercase letter
                                                </li>
                                                <li className={passwordRequirements.number ? 'met' : 'unmet'}>
                                                    {passwordRequirements.number ? <FaCheck /> : <FaCross />} Contain at least one number
                                                </li>
                                                <li className={passwordRequirements.special ? 'met' : 'unmet'}>
                                                    {passwordRequirements.special ? <FaCheck /> : <FaCross />} Contain at least one special character (@$!%*?&)
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                    <div className="state-input-group">
                                <input
                                    type="text"
                                    placeholder="State"
                                    value={stateInput}
                                    onChange={handleStateChange}
                                    onClick={() => setShowStateDropdown(true)}
                                    required
                                />
                                {stateInput && (
                                    <button
                                        type="button"
                                        onClick={resetState}
                                        className="reset-state-btn"
                                    >
                                        <FaTimes />
                                    </button>
                                )}
                                <FaChevronDown 
                                    className="dropdown-icon" 
                                    onClick={() => setShowStateDropdown(!showStateDropdown)} 
                                />
                                {showStateDropdown && (
                                    <ul className="state-suggestions" style={{ listStyleType: 'none', padding: 0 }}>
                                        {filteredStates.map((state) => (
                                            <li key={state.abbreviation} onClick={() => selectState(state)}>
                                                {state.name}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                                    <button type="submit" className="create-account-btn" disabled={isLoading}>
                                        {isLoading ? 'Creating Account...' : 'Create Account'}
                                    </button>
                                </form>
                            </div>
                            <p className="login-link">Already have an account? <a href="#" onClick={handleSignInClick}>Log In</a></p>
                        </div>
                    )}
                </div>
                <div className="popup-right">
                    <button className="close-button" onClick={onClose}>
                        <FaTimes />
                    </button>
                    <div className="popup-right-content">
                        <p className="filler-text">Discover the world of espionage and intrigue.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUpPopup;