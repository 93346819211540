import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, ListGroup} from 'react-bootstrap';
import { ArrowRight, Trash2 } from 'lucide-react';
import styled from 'styled-components';
import { FaFolder, FaPlus } from 'react-icons/fa';
import PropertyCard from './PropertyCard';
import { createFolder, getFolders, savePropertyToFolder, removePropertyFromFolder } from '../utils/auth';
import { useMockLocation } from '../components/contexts/MockLocationContext';
import PropertyFolders from './Folders';
import { useSavedProperties, usePropertySavedState } from './hooks/usePropertySavedState';
import { SavedPropertiesProvider } from './hooks/usePropertySavedState';

// Move styled components outside the component
const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 15px;
    border: none;
  }

  .modal-header {
    background-color: var(--color-blue-main);
    color: white;
    border-radius: 15px 15px 0 0;
  }

  .list-group-item {
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background-color: #f8f9fa;
    }

    &.selected {
      background-color: #e9ecef;
    }
  }
`;

const FolderCount = styled.span`
  background-color: var(--color-blue-main);
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.8rem;
`;

const CreateFolderButton = styled(Button)`
  width: 100%;
  margin-top: 1rem;
  background-color: transparent;
  border: 2px dashed var(--color-blue-main);
  color: var(--color-blue-main);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  
  &:hover {
    background-color: var(--color-blue-main);
    color: white;
  }
`;
const FolderActions = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 12px;
  margin-bottom: 36px; 
  border-top: 1px solid #e5e7eb;
  padding-top: 12px;
  padding-bottom: 12px; 
`;

const ActionButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 0.9rem;
  padding: 6px 12px;
`;

const PropertyCardWrapper = ({
  property,
  isSubscribed,
  onEmailClick,
  onDocumentDownload,
  currentUser,
  onEdit,
  onDelete,
  showEditDelete = false,
  folderId,
  onRemoveFromFolder,
  onMoveProperty
}) => {
  const [showFolderModal, setShowFolderModal] = useState(false);
  const [isSaved, updateIsSaved] = usePropertySavedState(property.id, currentUser);

  useEffect(() => {
    // This will ensure the heart state is always in sync with folder status
    const checkSavedStatus = async () => {
      const folders = await getFolders();
      const isInAnyFolder = folders.some(folder => 
        folder.properties?.some(p => p.id === property.id || p.property_details?.id === property.id)
      );
      if (isInAnyFolder !== isSaved) {
        updateIsSaved(isInAnyFolder);
      }
    };

    if (currentUser) {
      checkSavedStatus();
    }
  }, [property.id, currentUser]);

  const handleFavoriteClick = () => {
    if (!currentUser) return;
    setShowFolderModal(true);
  };

  return (
    <>
      <PropertyCard
        property={property}
        isSubscribed={isSubscribed}
        onEmailClick={onEmailClick}
        onDocumentDownload={onDocumentDownload}
        onEdit={showEditDelete ? onEdit : undefined}
        onDelete={showEditDelete ? onDelete : undefined}
        isUploadSection={showEditDelete}
        currentUser={currentUser}
        onFavoriteClick={handleFavoriteClick}
        isSaved={isSaved}
      />

      {folderId && (
        <FolderActions>
          <ActionButton 
            variant="outline-primary"
            size="sm"
            onClick={() => onMoveProperty(property)}
          >
            <ArrowRight size={16} />
            Move to Another Folder
          </ActionButton>
          <ActionButton
            variant="outline-danger"
            size="sm"
            onClick={() => onRemoveFromFolder(property)}
          >
            <Trash2 size={16} />
            Remove from Folder
          </ActionButton>
        </FolderActions>
      )}

      {showFolderModal && (
        <PropertyFolders
          property={property}
          isOpen={showFolderModal}
          onClose={() => setShowFolderModal(false)}
          userProfile={currentUser}
          updateIsSaved={updateIsSaved}
        />
      )}
    </>
  );
};

export default PropertyCardWrapper;