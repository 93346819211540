import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Form, Button, ListGroup, Alert } from 'react-bootstrap';
import { FaUser, FaSearch, FaTimes } from 'react-icons/fa';
import { property_search } from '../utils/auth';
import debounce from 'lodash/debounce';
import { useMockLocation } from './contexts/MockLocationContext';

const HomeCover = ({ page, userProfile, onUpdateProfile, onSearch, onResetSearch }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const mockLocation = useMockLocation();
  const [isSearchCompleted, setIsSearchCompleted] = useState(false);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (searchQuery.trim().length === 0) {
      setError(null);
      onResetSearch();
      return;
    }

    setIsLoading(true);
    setError(null);
    setShowSuggestions(false);
    setSuggestions([]);
    setIsSearchCompleted(true);

    try {
      const results = await property_search(searchQuery, mockLocation, userProfile);

      if (results.length === 0) {
        const partialQuery = searchQuery.split(',')[0];
        const partialResults = await property_search(partialQuery, mockLocation, userProfile);

        if (partialResults.length > 0) {
          onSearch(partialResults);
        } else {
          setError('Sorry, no properties found in that area.');
          onResetSearch();
        }
      } else {
        onSearch(results);
      }
    } catch (err) {

      setError('An error occurred while searching. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetSearch = () => {
    setSearchQuery('');
    setSuggestions([]);
    setShowSuggestions(true);
    setError(null);
    setIsSearchCompleted(false);
    onResetSearch();
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    setShowSuggestions(true);
    setError(null);
    setIsSearchCompleted(false);

    if (value.trim().length >= 2) {
      debouncedFetchSuggestions(value);
    } else {
      setSuggestions([]);
      if (value.trim().length === 0) {
        onResetSearch();
      }
    }
  };

  const handleSuggestionClick = async (suggestion) => {
    setSearchQuery(suggestion.text);
    setSuggestions([]);
    setIsLoading(true);
    setError(null);
    setShowSuggestions(false);
    setIsSearchCompleted(true);

    try {
      const results = await property_search(suggestion.text, mockLocation, userProfile);

      if (results.length === 0) {
        const partialQuery = suggestion.text.split(',')[0];
        const partialResults = await property_search(partialQuery, mockLocation, userProfile);

        if (partialResults.length > 0) {
          onSearch(partialResults);
        } else {
          setError('Sorry, no properties found for this address.');
          onResetSearch();
        }
      } else {
        onSearch(results);
      }
    } catch (err) {
      console.error('[Search] Error:', err);
      setError('An error occurred while searching. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedFetchSuggestions = useCallback(
    debounce(async (query) => {
      if (query.length >= 2 && showSuggestions) {
        try {
          const response = await property_search(query, mockLocation, userProfile);


          const properties = Array.isArray(response) ? response : [response];

          if (properties.length > 0) {
            const formattedSuggestions = properties
              .slice(0, 7)
              .map(property => ({
                id: property.id,
                text: property.street_address ?
                  `${property.street_address}, ${property.city}, ${property.zip_code}` :
                  `${property.city}, ${property.state}`,
                price: property.price
              }));

            setSuggestions(formattedSuggestions);
          } else {
            setSuggestions([]);
          }
        } catch (err) {
          console.error('[Search] Error:', err);
          setSuggestions([]);
        }
      } else {
        setSuggestions([]);
      }
    }, 300),
    [showSuggestions, mockLocation, userProfile]
  );

  const renderSearchSection = () => (
    <Row className="justify-content-center">
      <Col lg={8} md={10} sm={12}>
        <Form onSubmit={handleSearch} className="d-flex flex-column flex-md-row">
          <div className="position-relative w-100 me-md-2 mb-2 mb-md-0">
            <Form.Control
              type="text"
              placeholder="Search by address, city, or zip code..."
              value={searchQuery}
              onChange={handleInputChange}
              disabled={isLoading}
              autoComplete="off"
              className="form-control-lg"
            />
            {searchQuery && (
              <Button
                variant="link"
                className="position-absolute top-50 end-0 translate-middle-y pe-3"
                onClick={handleResetSearch}
              >
                <FaTimes />
              </Button>
            )}
            {searchQuery.trim().length > 0 && suggestions.length > 0 && showSuggestions && !isSearchCompleted && (
              <ListGroup
                className="position-absolute w-100 mt-1 shadow"
                style={{
                  zIndex: 1000,
                  maxHeight: '300px',
                  overflowY: 'auto',
                  borderRadius: '8px'
                }}
              >
                {suggestions.map((suggestion) => (
                  <ListGroup.Item
                    key={suggestion.id}
                    action
                    onClick={() => handleSuggestionClick(suggestion)}
                    className="d-flex justify-content-between align-items-center py-3"
                  >
                    <div className="text-truncate me-2">
                      <span>{suggestion.text}</span>
                    </div>
                    {suggestion.price && (
                      <span className="text-muted small">
                        ${suggestion.price.toLocaleString()}
                      </span>
                    )}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}
          </div>
          <Button
            type="submit"
            className="flex-shrink-0 btn-lg"
            disabled={isLoading}
            style={{ backgroundColor: 'var(--color-blue-main)', borderColor: 'var(--color-blue-main)' }}
          >
            {isLoading ? (
              <>
                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                Searching...
              </>
            ) : (
              <>
                <FaSearch className="me-2" /> Search
              </>
            )}
          </Button>
        </Form>
        {error && searchQuery.trim().length > 0 && (
          <Alert variant="danger" className="mt-3">
            {error}
          </Alert>
        )}
      </Col>
    </Row>
  );

  const renderProfileSection = () => (
    <Row className="justify-content-center">
      <Col md={10} lg={8}>
        <div className="profile-card">
          <div className="text-center mb-4">
            <FaUser size={96} className="profile-icon mb-3" />
            <h2 className="mb-3">{userProfile.username}</h2>
          </div>
          <Row className="justify-content-center">
            <Col md={5} className="text-left" style={{ textAlign: 'left' }}>
              <p className="mb-2"><strong>Full Name:</strong> {userProfile.name || 'Not set'}</p>
              <p className="mb-2"><strong>Email:</strong> {userProfile.email}</p>
              <p className="mb-2"><strong>Phone:</strong> {userProfile.profile?.phone || 'Not set'}</p>
              <p className="mb-2"><strong>Company:</strong> {userProfile.profile?.company || 'Not set'}</p>
            </Col>
            <Col md={5} className="text-left" style={{ textAlign: 'left' }}>
              <p className="mb-2"><strong>State:</strong> {userProfile.profile?.state || 'Not set'}</p>
              <p className="mb-2"><strong>State ID:</strong> {userProfile.profile?.state_id || 'Not set'}</p>
              <p className="mb-2"><strong>Business Address:</strong> {userProfile.profile?.business_address || 'Not set'}</p>
            </Col>
          </Row>
          <div className="text-center mt-4">
            <Button
              variant="primary"
              onClick={onUpdateProfile}
              style={{ backgroundColor: "#18407E" }}
            >
              Update Profile
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
  return (
    <section className="home-cover hero-section">
      <Container>
        {page === 'profile' && userProfile ? renderProfileSection() : renderSearchSection()}
      </Container>
    </section>
  );
};

export default HomeCover;