import { useState, useEffect } from 'react';

const SCRIPT_ID = 'google-maps-script';
const LOADING_TIMEOUT = 10000; // 10 seconds timeout

const useGoogleMapsScript = () => {
  const [status, setStatus] = useState({
    isLoaded: false,
    error: null
  });

  useEffect(() => {
    // If the script is already loaded and Google Maps is available
    if (window.google?.maps) {
      setStatus({ isLoaded: true, error: null });
      return;
    }

    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    if (!apiKey) {
      setStatus({ 
        isLoaded: false, 
        error: new Error('Google Maps API key is missing') 
      });
      return;
    }

    // Check for existing script
    let script = document.getElementById(SCRIPT_ID);
    
    if (!script) {
      script = document.createElement('script');
      script.id = SCRIPT_ID;
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
      script.async = true;
      script.defer = true;
      
      // Set up load timeout
      const timeoutId = setTimeout(() => {
        if (!window.google?.maps) {
          setStatus({
            isLoaded: false,
            error: new Error('Google Maps script load timeout')
          });
        }
      }, LOADING_TIMEOUT);

      // Script load handlers
      const handleLoad = () => {
        clearTimeout(timeoutId);
        if (window.google?.maps) {
          setStatus({ isLoaded: true, error: null });
        }
      };

      const handleError = () => {
        clearTimeout(timeoutId);
        setStatus({
          isLoaded: false,
          error: new Error('Failed to load Google Maps script')
        });
      };

      script.addEventListener('load', handleLoad);
      script.addEventListener('error', handleError);

      document.head.appendChild(script);

      return () => {
        script.removeEventListener('load', handleLoad);
        script.removeEventListener('error', handleError);
        clearTimeout(timeoutId);
      };
    }
  }, []);

  return status;
};

export default useGoogleMapsScript;