import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Alert } from 'react-bootstrap';
import { FaFolderPlus } from 'react-icons/fa';

export default function BatchSaveModal({ 
  isOpen, 
  onClose, 
  selectedProperties, 
  folders = [], 
  onSave,
  loading,
  onCreateFolder 
}) {
  const [selectedFolderId, setSelectedFolderId] = useState('');
  const [notes, setNotes] = useState('');
  const [error, setError] = useState(null);
  const [showCreateFolder, setShowCreateFolder] = useState(false);
  const [newFolderData, setNewFolderData] = useState({
    name: '',
    description: ''
  });

  // Reset form when modal closes
  useEffect(() => {
    if (!isOpen) {
      setSelectedFolderId('');
      setNotes('');
      setError(null);
      setShowCreateFolder(false);
      setNewFolderData({ name: '', description: '' });
    }
  }, [isOpen]);

  const handleSave = async () => {
    if (!selectedFolderId) {
      setError('Please select a folder');
      return;
    }

    try {
      await onSave(selectedFolderId, notes);
      setSelectedFolderId('');
      setNotes('');
      setError(null);
      onClose();
    } catch (err) {
      setError(err.message || 'Failed to save properties to folder');
    }
  };

  const handleCreateNewFolder = async (e) => {
    e.preventDefault();
    if (!newFolderData.name.trim()) {
      setError('Please enter a folder name');
      return;
    }
  
    try {
      const newFolder = await onCreateFolder(newFolderData.name, newFolderData.description);
      setSelectedFolderId(newFolder.id);
      // After folder is created, save the properties
      await handleSave();
      setShowCreateFolder(false);
      setNewFolderData({ name: '', description: '' });
      setError(null);
    } catch (err) {
      setError(err.message || 'Failed to create folder');
    }
  };

  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{showCreateFolder ? 'Create New Folder' : 'Add to Folder'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!showCreateFolder ? (
          <>
            <p className="text-muted">
              Select a folder to add {selectedProperties.size} properties to:
            </p>

            {error && (
              <Alert variant="danger" className="mb-3" onClose={() => setError(null)} dismissible>
                {error}
              </Alert>
            )}

            <Form.Group className="mb-3">
              <Form.Select
                value={selectedFolderId}
                onChange={(e) => setSelectedFolderId(e.target.value)}
                disabled={loading}
              >
                <option value="">Select a folder...</option>
                {folders.map((folder) => (
                  <option key={folder.id} value={folder.id}>
                    {folder.name} ({folder.property_count || 0} properties)
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Control
                as="textarea"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder="Add notes (optional)"
                rows={3}
                disabled={loading}
              />
            </Form.Group>

            <Button
              variant="outline-primary"
              className="w-100"
              onClick={() => setShowCreateFolder(true)}
            >
              <FaFolderPlus className="me-2" />
              Create New Folder
            </Button>
          </>
        ) : (
          <Form onSubmit={handleCreateNewFolder}>
            {error && (
              <Alert variant="danger" className="mb-3" onClose={() => setError(null)} dismissible>
                {error}
              </Alert>
            )}
            
            <Form.Group className="mb-3">
              <Form.Label>Folder Name</Form.Label>
              <Form.Control
                type="text"
                value={newFolderData.name}
                onChange={(e) => setNewFolderData(prev => ({ ...prev, name: e.target.value }))}
                placeholder="Enter folder name"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Description (optional)</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={newFolderData.description}
                onChange={(e) => setNewFolderData(prev => ({ ...prev, description: e.target.value }))}
                placeholder="Enter folder description"
              />
            </Form.Group>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button 
          variant="secondary" 
          onClick={() => {
            if (showCreateFolder) {
              setShowCreateFolder(false);
              setError(null);
            } else {
              onClose();
            }
          }}
          disabled={loading}
        >
          {showCreateFolder ? 'Back' : 'Cancel'}
        </Button>
        <Button
          variant="primary"
          onClick={showCreateFolder ? handleCreateNewFolder : handleSave}
          disabled={loading || (!showCreateFolder && !selectedFolderId)}
        >
          {loading ? 'Saving...' : (showCreateFolder ? 'Create Folder' : 'Save to Folder')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}