import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Form, InputGroup, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaSearch, FaUser, FaBuilding, FaMapMarkerAlt, FaEnvelope } from 'react-icons/fa';
import { fetchAllProperties, fetchActiveUsers } from '../utils/auth';

const AgentsContainer = styled(Container)`
  padding-top: 120px;
  padding-bottom: 60px;
`;

const AgentCard = styled(Card)`
  margin-bottom: 20px;
  transition: all 0.3s ease;
  height: 100%;
  border: none;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  }
  
  .agent-details {
    color: #666;
    font-size: 0.9rem;
    margin: 12px 0;
    
    svg {
      color: var(--color-blue-main);
      margin-right: 8px;
    }
  }
`;

const AgentImage = styled.div`
  height: 180px;
  background: linear-gradient(135deg, var(--color-blue-main), #2c5282);
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg {
    font-size: 70px;
    color: white;
  }
`;

const SearchBar = styled(Form)`
  margin-bottom: 40px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  
  .input-group {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    overflow: hidden;
  }
  
  .input-group-text {
    background-color: white;
    border: none;
    padding-left: 20px;
  }
  
  .form-control {
    border: none;
    padding: 12px 20px;
    font-size: 1rem;
    
    &:focus {
      box-shadow: none;
    }
  }
`;

const StyledButton = styled(Button)`
  background: linear-gradient(135deg, var(--color-blue-main), #2c5282);
  color:white;
  textDecoration:none;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  transition: all 0.3s ease;
  width: 100%;
  
  &:hover {
    opacity: 0.9;
    transform: translateY(-2px);
  }
`;

const PageTitle = styled.h1`
  text-align: center;
  margin-bottom: 40px;
  color: #2d3748;
  font-weight: 600;
  font-size: 2.5rem;
  
  &:after {
    content: '';
    display: block;
    width: 60px;
    height: 4px;
    background: var(--color-blue-main);
    margin: 20px auto;
    border-radius: 2px;
  }
`;

const LoadingSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  
  .spinner-message {
    text-align: center;
    margin-left: 15px;
    color: #4a5568;
    font-size: 1.1rem;
  }
`;

const NoAgents = styled.div`
  text-align: center;
  margin: 60px 0;
  color: #4a5568;
  
  svg {
    font-size: 48px;
    color: var(--color-blue-main);
    margin-bottom: 20px;
  }
  
  p {
    font-size: 1.1rem;
    margin: 10px 0;
  }
`;

const Agents = () => {
    const [agents, setAgents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
  
    useEffect(() => {
        const fetchAgents = async () => {
          try {
            setIsLoading(true);
            const response = await fetchActiveUsers();
            setAgents(response.users || []);
          } catch (err) {
            console.error('Error loading agents:', err);
            setError('Failed to load agents. Please try again later.');
          } finally {
            setIsLoading(false);
          }
        };
    
        fetchAgents();
      }, []);
    
 
      const filteredAgents = agents.filter(agent =>
        agent.first_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        agent.last_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        agent.username?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        agent.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        agent.profile?.company?.toLowerCase().includes(searchTerm.toLowerCase())
      );

  if (isLoading) {
    return (
      <AgentsContainer>
        <LoadingSpinner>
          <Spinner animation="border" variant="primary" size="lg" />
          <div className="spinner-message">Loading agents...</div>
        </LoadingSpinner>
      </AgentsContainer>
    );
  }

  if (error) {
    return (
      <AgentsContainer>
        <NoAgents>
          <FaBuilding />
          <p className="text-danger">{error}</p>
        </NoAgents>
      </AgentsContainer>
    );
  }

  return (
    <AgentsContainer>
      <PageTitle>Our Real Estate Professionals</PageTitle>
      
      <SearchBar>
        <InputGroup>
          <InputGroup.Text>
            <FaSearch />
          </InputGroup.Text>
          <Form.Control
            type="text"
            placeholder="Search by name, email, or company"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </InputGroup>
      </SearchBar>
      
      {filteredAgents.length > 0 ? (
        <Row xs={1} md={2} lg={3} className="g-4">
          {filteredAgents.map(agent => (
            <Col key={agent.id}>
            <AgentCard>
  <AgentImage>
    <FaUser />
  </AgentImage>
  <Card.Body className="d-flex flex-column">
    <Card.Title className="h4 mb-3">
      {agent.first_name && agent.last_name 
        ? `${agent.first_name} ${agent.last_name}`
        : agent.username}
    </Card.Title>
    <div className="agent-details">
      {agent.profile?.company && (
        <div>
          <FaBuilding />
          {agent.profile.company}
        </div>
      )}
      {agent.profile?.state && (
        <div>
          <FaMapMarkerAlt />
          {agent.profile.state}
        </div>
      )}
      <div>
        <FaEnvelope />
        {agent.email}
      </div>
    </div>
    <div className="mt-2 mb-3 text-muted">
      {agent.property_count} {agent.property_count === 1 ? 'Listing' : 'Listings'}
    </div>
    <StyledButton 
      as={Link} 
      to={`/agent/${agent.id}`} 
      className="mt-auto"
    >
      View Profile
    </StyledButton>
  </Card.Body>
</AgentCard>
            </Col>
          ))}
        </Row>
      ) : (
        <NoAgents>
          <FaBuilding />
          <p>{searchTerm ? 'No agents found matching your search.' : 'No agents found.'}</p>
        </NoAgents>
      )}
    </AgentsContainer>
  );
};

export default Agents;