import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, ListGroup, Alert, Toast } from 'react-bootstrap';
import { Folder, FolderPlus, Trash2, Edit2, X, ArrowRight, ChevronDown, ChevronUp } from 'lucide-react';
import { 
  createFolder, 
  getFolders, 
  savePropertyToFolder,
  removePropertyFromFolder,
  updateFolder,
  updatePropertyNotesInFolder,
  getFolderProperties,
  movePropertyBetweenFolders
} from '../utils/auth';
import styled from 'styled-components';

const Description = styled.div`
  font-size: 0.875rem;
  color: #6c757d;
  margin-top: 4px;
`;

const Notes = styled.div`
  font-size: 0.875rem;
  color: #6c757d;
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid #e9ecef;
`;

const FolderListItem = styled(ListGroup.Item)`
  &:hover {
    background-color: #f8f9fa;
  }
`;
const SaveToExistingFolderForm = ({ folder, onSave, onCancel }) => {
  const [notes, setNotes] = useState('');

  return (
    <Form>
      <Form.Group className="mb-3">
        <Form.Label>Add Notes for this Property</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          placeholder="Add any notes about this property (optional)"
        />
      </Form.Group>
      <div className="d-flex gap-2">
        <Button 
          variant="primary" 
          onClick={() => onSave(notes)}
        >
          Save to Folder
        </Button>
        <Button 
          variant="outline-secondary"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </div>
    </Form>
  );
};

const PropertyFolders = ({ property, isOpen, onClose, userProfile, updateIsSaved }) => {
  const [folders, setFolders] = useState([]);
  const [showNewFolderForm, setShowNewFolderForm] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [newFolderData, setNewFolderData] = useState({ 
    name: '', 
    description: '', 
    notes: '' 
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [expandedNotes, setExpandedNotes] = useState({});
  const [editMode, setEditMode] = useState(null);
  const [editingData, setEditingData] = useState({ description: '', notes: '' });
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [targetFolder, setTargetFolder] = useState(null);
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);
  const [folderToRemoveFrom, setFolderToRemoveFrom] = useState(null);
  const [showSaveToFolderForm, setShowSaveToFolderForm] = useState(false);
  const toggleDescription = (folderId) => {
    setExpandedDescriptions(prev => ({
      ...prev,
      [folderId]: !prev[folderId]
    }));
  };
  const handleUpdateFolder = async (folderId) => {
    try {
      // Update folder description
      await updateFolder(folderId, {
        name: folders.find(f => f.id === folderId).name,
        description: editingData.description
      });

      // If there are notes and the property is in the folder, update them
      if (editingData.notes && folders.find(f => f.id === folderId).hasProperty) {
        await updatePropertyNotesInFolder(folderId, property.id, editingData.notes);
      }

      showToastMessage('Folder updated successfully');
      setEditMode(null);
      await loadFolders();
    } catch (err) {
      setError('Failed to update folder');
    }
  };



  const handleSaveToExistingFolder = async (notes) => {
    try {
      await savePropertyToFolder(property.id, { 
        folder_id: selectedFolder.id,
        notes: notes.trim()
      });
      showToastMessage(`Saved to "${selectedFolder.name}"`);
      setShowSaveToFolderForm(false);
      setSelectedFolder(null);
      await loadFolders();
      updateIsSaved?.(true);
    } catch (err) {
      setError(err.message || 'Failed to save to folder');
    }
  };

  useEffect(() => {
    console.log('PropertyFolders mounted or isOpen changed:', isOpen);
    if (isOpen) {
      loadFolders();
    }
  }, [isOpen, property?.id]);

  const loadFolders = async () => {
    console.log('Loading folders...');
    try {
      setLoading(true);
      setError(null);
      const data = await getFolders();
      console.log('Fetched folders:', data);
      
      const foldersWithDetails = await Promise.all(
        data.map(async (folder) => {
          try {
            const properties = await getFolderProperties(folder.id);
            console.log(`Properties for folder ${folder.id}:`, properties);
            
            const propertyInFolder = properties.find(p => 
              p.id === property.id || p.property_details?.id === property.id
            );
            
            return {
              ...folder,
              properties,
              hasProperty: !!propertyInFolder,
              propertyNotes: propertyInFolder?.notes || '',
              description: folder.description || ''
            };
          } catch (err) {
            console.error(`Error loading properties for folder ${folder.id}:`, err);
            return {
              ...folder,
              properties: [],
              hasProperty: false,
              propertyNotes: '',
              description: folder.description || ''
            };
          }
        })
      );
      
      console.log('Processed folders with details:', foldersWithDetails);
      setFolders(foldersWithDetails);
      updateIsSaved?.(foldersWithDetails.some(f => f.hasProperty));
    } catch (err) {
      console.error('Error loading folders:', err);
      setError('Failed to load folders');
    } finally {
      setLoading(false);
    }
  };

  const handleCreateFolder = async (e) => {
    e.preventDefault();
    console.log('Creating new folder with data:', newFolderData);
    
    try {
      const newFolder = await createFolder(
        newFolderData.name.trim(),
        newFolderData.description.trim()
      );
      console.log('Created folder:', newFolder);

      if (property?.id) {
        await savePropertyToFolder(property.id, {
          folder_id: newFolder.id,
          notes: newFolderData.notes.trim()
        });
        console.log('Saved property to new folder with notes');
      }

      showToastMessage(`Created folder "${newFolderData.name}" and saved property`);
      setNewFolderData({ name: '', description: '', notes: '' });
      setShowNewFolderForm(false);
      updateIsSaved?.(true);
      await loadFolders();
    } catch (err) {
      console.error('Error creating folder:', err);
      setError(err.message || 'Failed to create folder');
    }
  };
  const handleMoveProperty = async () => {
    if (!selectedFolder || !targetFolder) return;
    
    try {
      await movePropertyBetweenFolders(
        property.id,
        selectedFolder.id,
        targetFolder.id
      );
      showToastMessage(`Moved property to "${targetFolder.name}"`);
      setShowMoveModal(false);
      setSelectedFolder(null);
      setTargetFolder(null);
      await loadFolders();
    } catch (err) {
      setError('Failed to move property');
    }
  };
  
  const handleUpdatePropertyNotes = async (folderId, notes) => {
    try {
      await updatePropertyNotesInFolder(folderId, property.id, notes);
      showToastMessage('Notes updated successfully');
      await loadFolders();
    } catch (err) {
      setError('Failed to update notes');
    }
  };

  const handleToggleInFolder = async (folder) => {
    try {
      if (folder.hasProperty) {
        setFolderToRemoveFrom(folder);
        setShowRemoveConfirm(true);
      } else {
        setSelectedFolder(folder);
        setShowSaveToFolderForm(true);
      }
    } catch (err) {
      setError(err.message || 'Failed to update folder');
    }
  };
  const handleRemoveFromFolder = async () => {
    try {
      await removePropertyFromFolder(folderToRemoveFrom.id, property.id);
      showToastMessage(`Removed from "${folderToRemoveFrom.name}"`);
      setShowRemoveConfirm(false);
      setFolderToRemoveFrom(null);
      await loadFolders();
      updateIsSaved?.(false);
    } catch (err) {
      setError('Failed to remove property');
    }
  };


  const toggleNotes = (folderId) => {
    setExpandedNotes(prev => ({
      ...prev,
      [folderId]: !prev[folderId]
    }));
  };

  const showToastMessage = (message) => {
    setToastMessage(message);
    setShowToast(true);
  };

  const renderExpandableText = (text, isExpanded, toggleFn) => {
    if (!text) return null;
    const shouldTruncate = text.length > 100;
    
    if (!shouldTruncate) return text;
    
    return (
      <>
        {isExpanded ? text : `${text.substring(0, 100)}...`}
        <Button
          variant="link"
          size="sm"
          className="p-0 ms-1"
          onClick={(e) => {
            e.stopPropagation();
            toggleFn();
          }}
        >
          {isExpanded ? (
            <>
              <ChevronUp size={14} className="mb-1" /> Show less
            </>
          ) : (
            <>
              <ChevronDown size={14} className="mb-1" /> Show more
            </>
          )}
        </Button>
      </>
    );
  };

  return (
    <>
      <Modal show={isOpen} onHide={onClose} size="md" centered>
        <Modal.Header closeButton>
          <Modal.Title>Save to Folder</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          {error && (
            <Alert variant="danger" className="m-3" onClose={() => setError(null)} dismissible>
              {error}
            </Alert>
          )}

          {loading ? (
            <div className="text-center p-4">Loading folders...</div>
          ) : (
            <div className="px-3">
              {!showNewFolderForm ? (
                <>
                  <ListGroup variant="flush" className="mb-3">
                  {folders.map((folder) => (
  <FolderListItem key={folder.id} className="py-3">
    <div className="d-flex justify-content-between align-items-start">
      <div className="d-flex align-items-start gap-2">
        <Folder size={18} className="text-primary mt-1" />
        <div style={{ flex: 1, minWidth: 0 }}>
          <div className="fw-medium">{folder.name}</div>
          {editMode === folder.id ? (
  <Form>
    <Form.Group className="mb-3">
      <Form.Label>Folder Description</Form.Label>
      <Form.Control
        as="textarea"
        value={editingData.description}
        onChange={(e) => setEditingData(prev => ({
          ...prev,
          description: e.target.value
        }))}
      />
    </Form.Group>
    {folder.hasProperty && (
      <Form.Group className="mb-3">
        <Form.Label>Property Notes</Form.Label>
        <Form.Control
          as="textarea"
          value={editingData.notes}
          onChange={(e) => setEditingData(prev => ({
            ...prev,
            notes: e.target.value
          }))}
        />
      </Form.Group>
    )}
    <div className="d-flex gap-2">
      <Button 
        variant="primary" 
        onClick={() => handleUpdateFolder(folder.id)}
      >
        Save
      </Button>
      <Button 
        variant="secondary"
        onClick={() => setEditMode(null)}
      >
        Cancel
      </Button>
    </div>
  </Form>
) : (
            <>
              {folder.description && (
                <Description>
                  {renderExpandableText(
                    folder.description,
                    expandedDescriptions[folder.id],
                    () => toggleDescription(folder.id)
                  )}
                </Description>
              )}
              {folder.hasProperty && folder.propertyNotes && (
                <Notes>
                  <strong>Property Notes:</strong>
                  <div>
                    {renderExpandableText(
                      folder.propertyNotes,
                      expandedNotes[folder.id],
                      () => toggleNotes(folder.id)
                    )}
                  </div>
                </Notes>
              )}
            </>
          )}
        </div>
      </div>
      <div className="d-flex gap-2">
        {!editMode && (
          <>
            <Button
              variant="link"
              className="p-1"
              onClick={() => {
                setEditMode(folder.id);
                setEditingData({
                  description: folder.description || '',
                  notes: folder.propertyNotes || ''
                });
              }}
            >
              <Edit2 size={16} />
            </Button>
            {folder.hasProperty && (
              <Button
                variant="outline-secondary"
                size="sm"
                onClick={() => {
                  setSelectedFolder(folder);
                  setShowMoveModal(true);
                }}
              >
                <ArrowRight size={16} />
              </Button>
            )}
            <Button
              variant={folder.hasProperty ? "success" : "outline-primary"}
              size="sm"
              onClick={() => handleToggleInFolder(folder)}
            >
              {folder.hasProperty ? "Saved" : "Save"}
            </Button>
          </>
        )}
      </div>
    </div>
  </FolderListItem>
))}
                  </ListGroup>
                  <Button
                    variant="outline-primary"
                    className="w-100 mb-3"
                    onClick={() => setShowNewFolderForm(true)}
                  >
                    <FolderPlus size={18} className="me-2" />
                    Create New Folder
                  </Button>
                </>
              ) : (
                <Form onSubmit={handleCreateFolder} className="py-3">
                  <Form.Group className="mb-3">
                    <Form.Label>Folder Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={newFolderData.name}
                      onChange={e => setNewFolderData(prev => ({ 
                        ...prev, 
                        name: e.target.value 
                      }))}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Description (optional)</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      value={newFolderData.description}
                      onChange={e => setNewFolderData(prev => ({ 
                        ...prev, 
                        description: e.target.value 
                      }))}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Property Notes (optional)</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      value={newFolderData.notes}
                      onChange={e => setNewFolderData(prev => ({ 
                        ...prev, 
                        notes: e.target.value 
                      }))}
                    />
                  </Form.Group>
                  <div className="d-flex gap-2">
                    <Button type="submit" variant="primary">Create & Save</Button>
                    <Button 
                      variant="outline-secondary"
                      onClick={() => setShowNewFolderForm(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>

      {/* Move Property Modal */}
      <Modal show={showMoveModal} onHide={() => setShowMoveModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Move Property</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Select Destination Folder</Form.Label>
            <Form.Select 
              value={targetFolder?.id || ''} 
              onChange={e => setTargetFolder(folders.find(f => f.id === parseInt(e.target.value)))}
            >
              <option value="">Choose folder...</option>
              {folders
                .filter(f => f.id !== selectedFolder?.id)
                .map(folder => (
                  <option key={folder.id} value={folder.id}>
                    {folder.name}
                  </option>
                ))
              }
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowMoveModal(false)}>
            Cancel
          </Button>
          <Button 
            variant="primary" 
            onClick={handleMoveProperty}
            disabled={!targetFolder}
          >
            Move Property
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Remove Confirmation Modal */}
      <Modal show={showRemoveConfirm} onHide={() => setShowRemoveConfirm(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Remove Property</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to remove this property from "{folderToRemoveFrom?.name}"?</p>
          <p className="text-muted mb-0">This will only remove the property from this folder.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowRemoveConfirm(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleRemoveFromFolder}>
            Remove
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
  show={showSaveToFolderForm}
  onHide={() => {
    setShowSaveToFolderForm(false);
    setSelectedFolder(null);
  }}
  centered
>
  <Modal.Header closeButton>
    <Modal.Title>Save to {selectedFolder?.name}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <SaveToExistingFolderForm
      folder={selectedFolder}
      onSave={handleSaveToExistingFolder}
      onCancel={() => {
        setShowSaveToFolderForm(false);
        setSelectedFolder(null);
      }}
    />
  </Modal.Body>
</Modal>

      {/* Toast notifications */}
      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={3000}
        autohide
        style={{
          position: 'fixed',
          bottom: 20,
          right: 20,
          minWidth: 200,
          zIndex: 1050
        }}
      >
        <Toast.Header>
          <strong className="me-auto">Folder Update</strong>
        </Toast.Header>
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
    </>
  );
};

export default PropertyFolders;