import React, { createContext, useContext, useState, useEffect } from 'react';
import { Card, Form } from 'react-bootstrap';
import styled from 'styled-components';

const MockLocationContext = createContext();

const LocationToggleContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
`;

const StyledCard = styled(Card)`
  background: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  
  .card-header {
    background: var(--color-blue-main);
    color: white;
    font-weight: 500;
    border-radius: 10px 10px 0 0;
  }
`;

export const MockLocationProvider = ({ children }) => {
    const [mockLocation, setMockLocation] = useState({
      enabled: false,
      coordinates: { lat: 36.7783, lng: -119.4179 }
    });
  
    const toggleMockLocation = () => {
      const newEnabled = !mockLocation.enabled;
      console.log(`[Location] Toggling mock location: ${newEnabled ? 'Enabled' : 'Disabled'}`);
      console.log(`[Location] Using coordinates: ${newEnabled ? 'CA (36.7783, -119.4179)' : 'Browser Location'}`);
      
      setMockLocation(prev => ({
        ...prev,
        enabled: newEnabled
      }));
    };
  
    useEffect(() => {
      const enabled = localStorage.getItem('mockLocationEnabled') === 'true';
      console.log(`[Location] Initial mock location state: ${enabled ? 'Enabled' : 'Disabled'}`);
      setMockLocation(prev => ({ ...prev, enabled }));
    }, []);
  
    useEffect(() => {
      localStorage.setItem('mockLocationEnabled', mockLocation.enabled);
    }, [mockLocation.enabled]);
  
    return (
      <MockLocationContext.Provider value={mockLocation}>
        {children}
        <LocationToggleContainer>
          <StyledCard>
            <Card.Header>Location Testing</Card.Header>
            <Card.Body>
              <Form>
                <Form.Check
                  type="switch"
                  id="mock-location-switch"
                  label={mockLocation.enabled ? 'Using Test Location (CA)' : 'Using Browser Location'}
                  checked={mockLocation.enabled}
                  onChange={toggleMockLocation}
                />
              </Form>
            </Card.Body>
          </StyledCard>
        </LocationToggleContainer>
      </MockLocationContext.Provider>
    );
};

export const useMockLocation = () => {
  const context = useContext(MockLocationContext);
  if (!context) {
    throw new Error('useMockLocation must be used within a MockLocationProvider');
  }
  return context;
};

export default MockLocationContext;