import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Button, Pagination, ButtonGroup, Table, Alert, Modal } from 'react-bootstrap';
import { FaThLarge, FaList, FaTable, FaPlus, FaHeart } from 'react-icons/fa';

import SubscriptionPopup from './SubscriptionPopup';
import UploadPropertyPopup from './UploadProperty';
import { fetchAllProperties, fetchUserProfile, updateUserProfile } from '../utils/auth';
import { FaEnvelope, FaDownload, FaExternalLinkAlt } from 'react-icons/fa';
import { MdVerified } from 'react-icons/md';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled from 'styled-components';
import PropertyCard from './PropertyCard';
import SignUpPopup from './SignUpPopup';
import PropertyCardWrapper from './PropertyCardWrapper';
import { useMockLocation } from '../components/contexts/MockLocationContext';
import { useSavedProperties, usePropertySavedState } from './hooks/usePropertySavedState';
import { SavedPropertiesProvider } from './hooks/usePropertySavedState';
import SavedPropertiesList from './SavedPropertiesList';
import {
  savePropertyToFolder,
  removePropertyFromFolder,
  getFoldersContainingProperty,
  getFolders,
  getFolderProperties,
  createFolder
} from '../utils/auth';
import { Image, Form } from 'react-bootstrap';
import { FaFolder } from 'react-icons/fa';
import {
  AlertDialog,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogAction,
  AlertDialogCancel,
} from '@radix-ui/react-alert-dialog';
import BatchSaveModal from './BatchSaveModal';

const LoadingSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  width: 100%;
  
  .spinner-border {
    color: var(--color-blue-main);
    width: 3rem;
    height: 3rem;
  }
`;



const BlurredText = styled.div`
  filter: blur(3px);
  user-select: none;
`;

const StyledButton = styled(Button)`
  background-color: var(--color-blue-main);
  color: #fff;
  border-color: var(--color-blue-main);
  &:hover, &:focus, &:active {
    background-color: var(--color-blue-main);
    border-color: var(--color-blue-main);
    opacity: 0.9;
  }
`;
const ThumbnailImage = styled(Image)`
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 6px;
`;

const BatchActionBar = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  background: #f8f9fa;
  border-radius: 8px;
  margin-bottom: 16px;

  button {
    background-color: var(--color-blue-main);
    border-color: var(--color-blue-main);
    
    &:hover, &:focus {
      background-color: var(--color-blue-main);
      border-color: var(--color-blue-main);
      opacity: 0.9;
    }
  }
`;

const styles = {
  content: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '20px',
    width: '400px',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    margin: '0 auto',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
    zIndex: 1000
  },
  title: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  description: {
    fontSize: '14px',
    marginBottom: '20px',
  },
  select: {
    width: '100%',
    padding: '10px',
    marginBottom: '20px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize: '14px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
  },
  button: {
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '14px',
  },
  cancelButton: {
    backgroundColor: '#f0f0f0',
    color: '#333',
  },
  actionButton: {
    backgroundColor: 'var(--color-blue-main)',
    color: 'white',
    '&:hover': {
      backgroundColor: 'var(--color-blue-main)',
      opacity: 0.9
    }
  }
};


const UploadCard = styled(Button)`
  height: 100%;
  width: 100%;
  background-color: #f8f9fa;
  border: 2px dashed var(--color-blue-main);
  color: var(--color-blue-main);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding:2rem;
  font-size: 1.5rem;
  &:hover {
    background-color: var(--color-blue-main);
    color: #fff;
  }
`;
const OverlayCard = styled.div`
  position: relative;
  height: 100%;
`;

const SubscribeOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 20px;
`;
const ActionButton = styled(Button)`
background-color: var(--color-blue-main);
border-color: var(--color-blue-main);
color: white;
margin: 0 4px;

&:hover, &:focus {
  background-color: var(--color-blue-main);
  border-color: var(--color-blue-main);
  opacity: 0.9;
}
`;
const QRCodeModal = styled(Modal)`
    .modal-content {
      background-color: white;
      border-radius: 12px;
      border: none;
    }
    
    .modal-header {
      background-color: var(--color-blue-main);
      color: white;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      border-bottom: none;
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2rem;
    }

    .qr-image {
      width: 300px;
      height: 300px;
      margin-bottom: 1rem;
    }

    .property-address {
      text-align: center;
      font-size: 1.1rem;
      color: #666;
      margin-top: 1rem;
    }
  `;
const ButtonContainer = styled.div`
display: flex;
gap: 8px;
`;

const StyledTableButton = styled(Button)`
  background-color: var(--color-blue-main);
  border-color: var(--color-blue-main);
  &:hover, &:focus {
    background-color: var(--color-blue-main);
    border-color: var(--color-blue-main);
    opacity: 0.9;
  }
`;


const Feed = ({ userProfile, onUserProfileUpdate, searchResults }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [showSubscriptionPopup, setShowSubscriptionPopup] = useState(false);
  const [showUploadPropertyPopup, setShowUploadPropertyPopup] = useState(false);
  const [properties, setProperties] = useState([]);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [viewMode, setViewMode] = useState('grid');
  const [isLoading, setIsLoading] = useState(false);
  const [showSignInPopup, setShowSignInPopup] = useState(false);
  const [error, setError] = useState(null);
  const itemsPerPage = 10;
  const mockLocation = useMockLocation();
  const { savedPropertyIds, refreshSavedProperties } = useSavedProperties();

  const [selectedProperties, setSelectedProperties] = useState(new Set());

  const [showFolderDialog, setShowFolderDialog] = useState(false);
  const [selectedFolderId, setSelectedFolderId] = useState('');
  const [folders, setFolders] = useState([]);
  const [showBatchSaveModal, setShowBatchSaveModal] = useState(false);
  const [batchSaveLoading, setBatchSaveLoading] = useState(false);


  useEffect(() => {
    const fetchFolders = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/folders`);
        const data = await response.json();
        setFolders(data);
      } catch (error) {
        console.error('Error fetching folders:', error);
      }
    };

    fetchFolders();
  }, []);

  const handleAddToFolder = async (selectedPropertyIds, folderId) => {
    try {
      await Promise.all(
        selectedPropertyIds.map((propertyId) =>
          savePropertyToFolder(propertyId, folderId)
        )
      );
      refreshSavedProperties();
      setError(null);
    } catch (err) {
      console.error('Failed to add properties to folder:', err);
      setError('Error adding properties to folder. Please try again.');
    }
  };
  const handleBatchSave = async (folderId, notes) => {
    setBatchSaveLoading(true);
    try {
      // Save properties one at a time instead of using Promise.all
      for (const propertyId of Array.from(selectedProperties)) {
        await savePropertyToFolder(propertyId, {
          folder_id: folderId,
          notes
        });
        // Add a small delay between requests
        await new Promise(resolve => setTimeout(resolve, 100));
      }

      await refreshSavedProperties();
      setShowBatchSaveModal(false);
      setSelectedProperties(new Set()); // Clear selections after successful save
    } catch (error) {
      setError('Failed to save properties to folder. Please try again.');
      throw error;
    } finally {
      setBatchSaveLoading(false);
    }
  };
  const handleCreateFolder = async (name, description) => {
    try {
      const newFolder = await createFolder(name, description);
      await loadFolders(); // Refresh folders list after creating new folder
      return newFolder;
    } catch (error) {
      throw new Error('Failed to create folder');
    }
  };


  const loadFolders = async () => {
    try {
      const foldersData = await getFolders();
      setFolders(foldersData);
    } catch (error) {
      setError('Failed to load folders');
    }
  };
  const batchLoadFolderProperties = async (folders) => {
    const batchSize = 3;
    const results = [];

    for (let i = 0; i < folders.length; i += batchSize) {
      const batch = folders.slice(i, i + batchSize);
      const batchPromises = batch.map(folder => getFolderProperties(folder.id));
      const batchResults = await Promise.all(batchPromises);
      results.push(...batchResults);


      if (i + batchSize < folders.length) {
        await new Promise(resolve => setTimeout(resolve, 300));
      }
    }

    return results;
  };

  const handleBatchAddToFolder = async () => {
    if (selectedFolderId && selectedProperties.size > 0) {
      try {

        for (const propertyId of selectedProperties) {
          await savePropertyToFolder(propertyId, selectedFolderId);
        }
        setSelectedProperties(new Set());
        setShowFolderDialog(false);
        await refreshSavedProperties();
      } catch (error) {
        setError('Failed to add properties to folder');
      }
    }
  };

  

  const handleToggleFavorite = useCallback(async (propertyId, isFavorite) => {
    try {
      if (isFavorite) {

        await savePropertyToFolder(propertyId);
      } else {

        const folders = await getFoldersContainingProperty(propertyId);

        for (const folder of folders) {
          await removePropertyFromFolder(folder.id, propertyId);
        }
      }

      await refreshSavedProperties();
    } catch (error) {
      console.error('Error toggling favorite:', error);
    }
  }, [refreshSavedProperties]);

  const checkSubscriptionStatus = useCallback(() => {
    if (userProfile && userProfile.subscription) {
      setIsSubscribed(userProfile.subscription.active);
    } else {
      setIsSubscribed(false);
    }
  }, [userProfile]);

  const loadAllProperties = useCallback(async () => {
    if (!userProfile) return;

    setIsLoading(true);
    setError(null);
    try {
      const [propertiesResponse, foldersResponse] = await Promise.all([
        fetchAllProperties(mockLocation, userProfile),
        getFolders()
      ]);

      const propertiesList = Array.isArray(propertiesResponse)
        ? propertiesResponse
        : propertiesResponse.properties || [];

      setProperties(propertiesList);


      if (foldersResponse.length > 0) {
        await batchLoadFolderProperties(foldersResponse);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [mockLocation, userProfile]);

  useEffect(() => {
    if (userProfile) {
      loadAllProperties();
    }
  }, [mockLocation.enabled, userProfile, loadAllProperties]);

  useEffect(() => {
    if (searchResults && searchResults.length > 0) {

      const searchResultsList = Array.isArray(searchResults) ? searchResults : searchResults.properties || [];
      setProperties(searchResultsList);
      setCurrentPage(1);
    } else if (!searchResults && userProfile) {
      loadAllProperties();
    }
    checkSubscriptionStatus();
  }, [loadAllProperties, checkSubscriptionStatus, searchResults, userProfile]);

  const handleUploadClick = () => {
    if (!userProfile) {
      setShowSignInPopup(true);
      return;
    }
    if (!userProfile.subscription?.active) {
      setShowSubscriptionPopup(true);
      return;
    }
    // Verify location before showing upload popup
    if (mockLocation.enabled || navigator.geolocation) {
      setShowUploadPropertyPopup(true);
    } else {
      setError('Please enable location services to upload properties');
    }
  };
  const handleShowBatchSaveModal = async () => {
    await loadFolders(); // Refresh folders when opening modal
    setShowBatchSaveModal(true);
  };
  const handleSubscriptionSuccess = async (updatedProfile) => {
    onUserProfileUpdate(updatedProfile);
    setIsSubscribed(true);
    setShowSubscriptionPopup(false);
    try {
      const latestProfile = await fetchUserProfile();
      onUserProfileUpdate(latestProfile);
      await loadAllProperties();
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Failed to update profile information');
    }
  };
  const getPropertyFolderInfo = useCallback(async (propertyId) => {
    try {
      const folders = await getFoldersContainingProperty(propertyId);
      return folders.map(f => f.name).join(', ');
    } catch (error) {
      console.error('Error getting folder info:', error);
      return '';
    }
  }, []);

  const handleCloseUploadPropertyPopup = async (uploadedProperty) => {
    setShowUploadPropertyPopup(false);
    if (uploadedProperty) {
      setProperties(prevProperties => Array.isArray(prevProperties) ? [uploadedProperty, ...prevProperties] : [uploadedProperty]);
    } else {
      await loadAllProperties();
    }
  };

  const handleDocumentDownload = (property) => {
    if (!userProfile) {
      setShowSignInPopup(true);
      return;
    }
    if (!isSubscribed) {
      setShowSubscriptionPopup(true);
      return;
    }
    if (property.pdf_file) {
      window.open(property.pdf_file, '_blank');
    } else {
      setError('No PDF document available for this property.');
    }
  };



  const handleEmailClick = (property) => {
    if (!userProfile) {
      setShowSignInPopup(true);
      return;
    }
    const subject = `Inquiry about property: ${property.street_address}, ${property.city}`;
    const body = `Hi,\n\nI'm interested in the property at ${property.street_address}, ${property.city}. Can you please provide more information?\n\nThank you,\n${userProfile.username}`;
    const mailtoLink = `mailto:${property.email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  const PropertyTable = ({
    properties,
    folders = [],
    onEmailClick,
    onAddToFolder,
    selectedProperties,
    onSelectProperties,
    onShowBatchSave,
    savedPropertyIds
  }) => {
    const [showFolderDialog, setShowFolderDialog] = useState(false);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [compensationFormat, setCompensationFormat] = useState('$');
    const [showQRModal, setShowQRModal] = useState(false);
    const [selectedQRUrl, setSelectedQRUrl] = useState('');
    const [selectedPropertyAddress, setSelectedPropertyAddress] = useState('');
  

    const LoadingSpinner = () => (
      <div className="d-flex justify-content-center align-items-center p-5">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
    const handleQRCodeClick = (url, address) => {
      setSelectedQRUrl(url);
      setSelectedPropertyAddress(address);
      setShowQRModal(true);
    };
  
  
    const handleSelectAll = (e) => {
      if (e.target.checked) {
        const selectableProperties = properties
          .filter(p => !savedPropertyIds.has(p.id))
          .map(p => p.id);
        onSelectProperties(new Set(selectableProperties));
      } else {
        onSelectProperties(new Set());
      }
    };
  
    const handleSelectProperty = (propertyId) => {
      const newSelected = new Set(selectedProperties);
      if (newSelected.has(propertyId)) {
        newSelected.delete(propertyId);
      } else {
        newSelected.add(propertyId);
      }
      onSelectProperties(newSelected);
    };
  
    const handleAddToFolder = async () => {
      if (selectedFolder && selectedProperties.size > 0) {
        await onAddToFolder([...selectedProperties], selectedFolder.id);
        onSelectProperties(new Set());
        setShowFolderDialog(false);
      }
    };
  
    const formatNumber = (num) => {
      return Math.floor(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
  
    const formatCompensation = (property) => {
      const metadata = localStorage.getItem(`property_${property.id}_metadata`);
      const storedData = metadata ? JSON.parse(metadata) : null;
      
      const selectedOption = storedData?.selectedOption || property.selectedOption;
      const concessionType = storedData?.concessionType || property.concessionType;
      const concession = property.concession;
      
      if (selectedOption === 'contact-agent' || !concession) {
        return 'Contact Agent';
      }
    
      const formattedValue = formatNumber(concession);
      return concessionType === 'percent' ? 
        `${formattedValue}%` : 
        `$${formattedValue}`;
    };
  
    if (!properties || properties.length === 0) {
      return <LoadingSpinner />;
    }
  
    return (
      <>
        {selectedProperties.size > 0 && (
          <BatchActionBar>
  <span>{selectedProperties.size} properties selected</span>
  <ActionButton
    variant="primary"
    size="sm"
    onClick={onShowBatchSave}
  >
    <FaFolder className="me-2" />
    Add to Folder
  </ActionButton>
</BatchActionBar>
        )}
  
        <Table striped bordered hover>
          <thead>
            <tr style={{ backgroundColor: 'var(--color-blue-main)', color: 'white' }}>
              <th>
                <Form.Check
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={selectedProperties.size === properties.filter(p => !savedPropertyIds.has(p.id)).length}
                />
              </th>
              <th>Property</th>
              <th>Price</th>
              <th>Compensation</th>
              <th>Property Size</th>
              <th>QR Code</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {properties.map((property) => {
              const sizeInfo = [
                property.bedrooms ? `${Math.floor(property.bedrooms)} bed` : null,
                property.bathrooms ? `${Math.floor(property.bathrooms)} bath` : null,
                property.sq_ft ? `${formatNumber(property.sq_ft)} sqft` : null
              ].filter(Boolean).join(' || ');
  
              const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=${encodeURIComponent(property.next_property_url || window.location.href)}`;
            const propertyAddress = `${property.street_address}, ${property.city}, ${property.zip_code}`;

              return (
                <tr key={property.id}>
                  <td>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        savedPropertyIds.has(property.id) ? (
                          <Tooltip id={`tooltip-${property.id}`}>
                            This property has already been saved
                          </Tooltip>
                        ) : (
                          <></>
                        )
                      }
                    >
                      <div>
                        <Form.Check
                          type="checkbox"
                          checked={selectedProperties.has(property.id)}
                          onChange={() => handleSelectProperty(property.id)}
                          disabled={savedPropertyIds.has(property.id)}
                          style={{
                            cursor: savedPropertyIds.has(property.id) ? 'not-allowed' : 'pointer',
                            opacity: savedPropertyIds.has(property.id) ? '0.6' : '1',
                            backgroundColor: savedPropertyIds.has(property.id) ? '#f8f9fa' : 'transparent',
                            padding: '4px',
                            borderRadius: '4px'
                          }}
                        />
                      </div>
                    </OverlayTrigger>
                  </td>
                  <td>
                    <div className="d-flex align-items-center gap-3">
                      <ThumbnailImage
                        src={property.image ? property.image : '/placeholder.svg'}
                        alt={property.street_address || 'Placeholder Image'}
                      />
                      <div>
                        {property.street_address}, {property.city}, {property.zip_code}
                      </div>
                    </div>
                  </td>
                  <td>${formatNumber(property.price)}</td>
                  <td>{formatCompensation(property)}</td>
                  <td>{sizeInfo}</td>
                  <td>
                  <img 
                    src={qrCodeUrl} 
                    alt="Property QR Code" 
                    style={{ 
                      width: '50px', 
                      height: '50px',
                      cursor: 'pointer' 
                    }}
                    onClick={() => handleQRCodeClick(qrCodeUrl, propertyAddress)}
                  />
                </td>
                  <td>
                    <ButtonContainer>
                      {property.next_property_url && (
                        <ActionButton
                          size="sm"
                          href={property.next_property_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FaExternalLinkAlt /> URL
                        </ActionButton>
                      )}
                      {property.pdf_file && (
                        <ActionButton
                          size="sm"
                          onClick={() => window.open(property.pdf_file, '_blank')}
                        >
                          <FaDownload /> Docs
                        </ActionButton>
                      )}
                      <ActionButton
                        size="sm"
                        onClick={() => onEmailClick(property)}
                      >
                        <FaEnvelope /> Email
                      </ActionButton>
                    </ButtonContainer>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <QRCodeModal
        show={showQRModal}
        onHide={() => setShowQRModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Property QR Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={selectedQRUrl}
            alt="Large QR Code"
            className="qr-image"
          />
          <div className="property-address">
            {selectedPropertyAddress}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ActionButton onClick={() => setShowQRModal(false)}>
            Close
          </ActionButton>
        </Modal.Footer>
      </QRCodeModal>
  
        <AlertDialog open={showFolderDialog} onOpenChange={setShowFolderDialog}>
          <AlertDialogContent style={styles.content}>
            <AlertDialogTitle style={styles.title}>Add to Folder</AlertDialogTitle>
            <AlertDialogDescription style={styles.description}>
              Select a folder to add {selectedProperties.size} properties to:
            </AlertDialogDescription>
            <select
              value={selectedFolder?.id || ''}
              onChange={(e) =>
                setSelectedFolder(folders.find((f) => f.id === e.target.value))
              }
              style={styles.select}
            >
              <option value="">Select a folder...</option>
              {folders.map((folder) => (
                <option key={folder.id} value={folder.id}>
                  {folder.name} ({folder.property_count} properties)
                </option>
              ))}
            </select>
            <div style={styles.buttonContainer}>
              <AlertDialogCancel asChild>
                <button style={{ ...styles.button, ...styles.cancelButton }}>
                  Cancel
                </button>
              </AlertDialogCancel>
              <AlertDialogAction asChild>
                <button
                  style={{ ...styles.button, ...styles.actionButton }}
                  onClick={handleBatchAddToFolder}
                >
                  Add to Folder
                </button>
              </AlertDialogAction>
            </div>
          </AlertDialogContent>
        </AlertDialog>
      </>
    );
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = properties.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  if (isLoading) {
    return (
      <LoadingSpinner>
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </LoadingSpinner>
    );
  }

  return (
    <SavedPropertiesProvider>
      <div className="feed">
        <Container>
          {error ? (
            <Alert variant="danger" className="mb-4" onClose={() => setError(null)} dismissible>
              <h4>Location Access Restricted</h4>
              <p>
                <strong>Access Denied:</strong> Properties can only be accessed from your registered state.
              </p>
              <p>
                Please update your location or contact support if you believe this is an error.
              </p>
              <p>
                <strong>Your registered state:</strong> {userProfile.profile.state}
              </p>
              <p>
                <strong>Detected location:</strong> (using your current location)
              </p>
            </Alert>
          ) : (
            <>
              <div className="d-flex justify-content-end align-items-center mb-3">
                <ButtonGroup>
                  {['grid', 'table', 'favorites'].map((mode) => (
                    <Button
                      key={mode}
                      variant={viewMode === mode ? 'primary' : 'outline-primary'}
                      onClick={() => setViewMode(mode)}
                      style={{
                        backgroundColor: viewMode === mode ? 'var(--color-blue-main)' : 'transparent',
                        borderColor: 'var(--color-blue-main)',
                        color: viewMode === mode ? '#fff' : 'var(--color-blue-main)'
                      }}
                    >
                      {mode === 'grid' && <FaThLarge />}
                      {mode === 'table' && <FaTable />}
                      {mode === 'favorites' && <FaHeart />}
                      {` ${mode.charAt(0).toUpperCase() + mode.slice(1)}`}
                    </Button>
                  ))}
                </ButtonGroup>
              </div>

              <Row>
                {viewMode !== 'table' && viewMode !== 'favorites' && (
                  <Col lg={6} xl={4} className="mb-4">
                    <OverlayCard>
                      <UploadCard onClick={handleUploadClick}>
                        <FaPlus />
                        <div>Upload Property</div>
                      </UploadCard>
                      {(!userProfile || !userProfile.subscription?.active) && (
                        <SubscribeOverlay>
                          <h4>Subscribe to Upload Properties</h4>
                          <StyledButton onClick={() => setShowSubscriptionPopup(true)}>
                            Subscribe Now
                          </StyledButton>
                        </SubscribeOverlay>
                      )}
                    </OverlayCard>
                  </Col>
                )}

                {viewMode === 'table' ? (
                  <Col xs={12}>
                    <PropertyTable
                      properties={currentItems}
                      folders={folders}
                      onEmailClick={handleEmailClick}
                      onAddToFolder={handleAddToFolder}
                      selectedProperties={selectedProperties}
                      onSelectProperties={setSelectedProperties}
                      onShowBatchSave={handleShowBatchSaveModal}
                      savedPropertyIds={savedPropertyIds}
                    />
                  </Col>
                ) : viewMode === 'favorites' ? (
                  <Col xs={12}>
                    <SavedPropertiesList
                      userProfile={userProfile}
                      onEmailClick={handleEmailClick}
                      onDocumentDownload={handleDocumentDownload}
                    />
                  </Col>
                ) : (
                  currentItems.map((property) => (
                    <Col key={property.id} lg={6} xl={4} className="mb-4">
                      <PropertyCardWrapper
                        property={property}
                        viewMode="grid"
                        isSubscribed={isSubscribed}
                        onEmailClick={handleEmailClick}
                        onDocumentDownload={handleDocumentDownload}
                        currentUser={userProfile}
                        isUploadSection={false}
                        onToggleFavorite={handleToggleFavorite}
                        isFavorite={savedPropertyIds.has(property.id)}
                      />
                    </Col>
                  ))
                )}
              </Row>
              {(viewMode === 'grid' || viewMode === 'table') && (
  <div className="d-flex justify-content-center mt-4">
    <Pagination>
      {Array.from({ length: Math.ceil(properties.length / itemsPerPage) }).map((_, index) => (
        <Pagination.Item
          key={index}
          active={index + 1 === currentPage}
          onClick={() => paginate(index + 1)}
        >
          {index + 1}
        </Pagination.Item>
      ))}
    </Pagination>
  </div>
)}
            </>
          )}
        </Container>
        {showSignInPopup && (
          <SignUpPopup
            onClose={() => setShowSignInPopup(false)}
            onSignUpSuccess={(user) => {
              onUserProfileUpdate(user);
              setShowSignInPopup(false);
            }}
          />
        )}

        {showSubscriptionPopup && (
          <SubscriptionPopup
            onClose={() => setShowSubscriptionPopup(false)}
            onSubscribe={handleSubscriptionSuccess}
            userProfile={userProfile}
          />
        )}
        <BatchSaveModal
          isOpen={showBatchSaveModal}
          onClose={() => setShowBatchSaveModal(false)}
          selectedProperties={selectedProperties}
          folders={folders}
          onSave={handleBatchSave}
          loading={batchSaveLoading}
          onCreateFolder={handleCreateFolder}
        />

        <UploadPropertyPopup
          show={showUploadPropertyPopup}
          onClose={() => setShowUploadPropertyPopup(false)}
          onUploadSuccess={handleCloseUploadPropertyPopup}
          userProfile={userProfile}
          mockLocation={mockLocation}
        />
      </div>
    </SavedPropertiesProvider>
  );
};

export default Feed;
