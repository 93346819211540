import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

export default function StartPage({ userProfile, onSignInClick }) {
  return (
    <div className="start-page">
      <main className="start-main">
        <div className="start-hero">
          <div className="start-icon">
            <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M3 21h18M3 10h18M3 7l9-4 9 4M4 10v11m16-11v11M8 21V10m8 11V10"/>
            </svg>
          </div>
          
          <h1 className="start-title">EMPOWERING AGENTS WITH TOOLS FOR SUCCESS</h1>
          
          <p className="start-description">Compare compensation contracts, network with professionals, and stay updated on market trends - all in one convenient platform.</p>
          
          <div className="start-buttons">
          <Link to="/properties" className="start-button primary">
              View Properties
            </Link>
            <Link to="/about" className="start-button secondary">
              Learn More
            </Link>
          </div>
        </div>
      </main>

      <footer className="start-footer">
        <div className="footer-nav">
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/terms">Terms of Service</Link>
        </div>
        <p className="copyright">© {new Date().getFullYear()} Agent Spyglass. All rights reserved.</p>
      </footer>
    </div>
  );
}